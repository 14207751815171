import Link from 'next/link'
import { useState } from 'react'
import type { PageLocaleResult } from '../data/content/PageLocaleFragment'
import type { SiteResult } from '../data/content/SiteFragment'
import { ContemberImage } from './ContemberImage'
import styles from './Header.module.sass'
import { LocaleSwitcher } from './LocaleSwitcher'
import { Navigation } from './Navigation'
import { Sandwich } from './Sandwich'

type HeaderProps = {
	header: SiteResult['getHeader']
	pageLocales?: PageLocaleResult
	activeLocale?: string
}
export const Header = (props: HeaderProps) => {
	const { header, pageLocales, activeLocale } = props
	const [modalIsOpen, setModalIsOpen] = useState(false)
	return (
		<>
			<div className={styles.wrapper}>
				<div className={styles.navigationButton}>
					<Sandwich
						title={header?.localesByLocale?.menuButtonText}
						onClick={() => setModalIsOpen(!modalIsOpen)}
					/>
				</div>
				{header?.logo && (
					<Link href="/">
						<a className={styles.logoWrapper}>
							<div className={styles.logo}>
								<div className={styles.logoIn}>
									<ContemberImage image={header?.logo} layout="fill" objectFit="contain" />
								</div>
							</div>
						</a>
					</Link>
				)}
				<div className={styles.localeSwitcher}>
					<LocaleSwitcher pageLocales={pageLocales} activeLocale={activeLocale} />
				</div>
			</div>

			<div>
				<Navigation
					isOpen={modalIsOpen}
					onClose={() => setModalIsOpen(false)}
					primaryLinks={header?.localesByLocale?.primaryLinkItems}
					secondaryLinks={header?.localesByLocale?.secondaryLinks?.items}
					socialLinks={header?.localesByLocale?.socialLinks}
					logo={header?.navigationLogo}
				/>
			</div>
		</>
	)
}

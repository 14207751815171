import type { SocialLinkListResult } from '../data/content/SocialLinkListFragment'
import { Icon } from './Icon'
import styles from './NavigationSocialLinks.module.sass'

type NavigationSocialLinksProps = {
	links?: SocialLinkListResult
}

export const NavigationSocialLinks = (props: NavigationSocialLinksProps) => {
	const { links } = props
	return (
		<div className={styles.wrapper}>
			{links?.items.map((item) => {
				return (
					<div key={item.id} className={styles.item}>
						{item.socialLink?.type === 'Facebook' && (
							<>
								<Icon name="facebook" />
								<div className={styles.title}>Facebook</div>
							</>
						)}
						{item.socialLink?.type === 'Instagram' && (
							<>
								<Icon name="instagram" />
								<div className={styles.title}>Instagram</div>
							</>
						)}
					</div>
				)
			})}
		</div>
	)
}

import type { TitleListResult } from '../data/content/TitleListFragment'
import { AmbiRichTextRenderer } from './AmbiRichTextRenderer'
import { ContemberImage } from './ContemberImage'
import styles from './FooterTitleList.module.sass'

type FooterTitleListProps = {
	footerTitleList: TitleListResult['items']
}

export const FooterTitleList = (props: FooterTitleListProps) => {
	const { footerTitleList } = props
	return (
		<>
			{footerTitleList.map((item) => {
				return (
					<div key={item.id} className={styles.item}>
						{item.title && <AmbiRichTextRenderer source={item.title} />}
						{item.icon && (
							<div className={styles.iconWrapper}>
								<div className={styles.icon}>
									<ContemberImage image={item.icon} layout="fill" objectFit="contain" />
								</div>
							</div>
						)}
					</div>
				)
			})}
		</>
	)
}

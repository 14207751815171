import type { LinkListResult } from '../data/content/LinkListFragment'
import { ContemberLink } from './ContemberLink'
import styles from './FooterLinkList.module.sass'

type FooterLinkListProps = {
	linkList?: LinkListResult
}

export const FooterLinkList = (props: FooterLinkListProps) => {
	const { linkList } = props
	return (
		<div className={styles.wrapper}>
			{linkList?.items.map((item) => {
				return (
					<div key={item.id} className={styles.title}>
						<ContemberLink link={item.link} />
					</div>
				)
			})}
		</div>
	)
}

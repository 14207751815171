import clsx from 'clsx'
import Link from 'next/link'
import type { PageLocaleResult } from '../data/content/PageLocaleFragment'
import styles from './LocaleSwitcher.module.sass'

export type LocaleSwitcherProps = {
	pageLocales?: PageLocaleResult
	activeLocale?: string
}
export const LocaleSwitcher = (props: LocaleSwitcherProps) => {
	const { pageLocales, activeLocale } = props
	return (
		<>
			<div className={styles.locales}>
				{pageLocales?.locales.map((locale) => {
					return (
						<div key={locale.id} className={clsx(styles.locale)}>
							{locale.link && activeLocale !== locale.locale?.code && (
								<Link href={locale.link?.url} locale={locale.locale?.code}>
									<a>{locale.locale?.code}</a>
								</Link>
							)}
						</div>
					)
				})}
			</div>
		</>
	)
}

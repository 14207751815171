/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Collapsible } from 'collapsible-react-component'
import 'collapsible-react-component/dist/index.css'
import { useState } from 'react'
import type { PrimaryLinkItemResult } from '../data/content/PrimaryLinkItemFragment'
import { PrimaryLink } from './PrimaryLink'

type PrimaryLinkItemProps = {
	item: PrimaryLinkItemResult
}

export const PrimaryLinksItem = (props: PrimaryLinkItemProps) => {
	const { item } = props
	const [showSubItems, setShowSubItems] = useState(false)
	return (
		<>
			{item.link && (
				<div onClick={() => setShowSubItems(!showSubItems)}>
					<PrimaryLink
						link={item.link}
						subLinksLength={item.subItems.length}
						showSubItems={showSubItems}
					/>
				</div>
			)}
			<Collapsible open={showSubItems}>
				<>
					{item.subItems.map((subItem) => {
						return (
							<div key={subItem.id}>
								{subItem.link && <PrimaryLink link={subItem.link} subLink />}
							</div>
						)
					})}
				</>
			</Collapsible>
		</>
	)
}

import type { SecondaryLinksItemResult } from '../data/content/SecondaryLinksItemFragment'
import { ContemberLink } from './ContemberLink'
import { Icon } from './Icon'
import styles from './SecondaryLinksItem.module.sass'

type SecondaryLinksItemProps = {
	link: SecondaryLinksItemResult
}

export const SecondaryLinksItem = (props: SecondaryLinksItemProps) => {
	const { link } = props
	return (
		<div className={styles.wrapper}>
			<ContemberLink link={link.link} />
			{link.iconType === 'shoppingBag' && <Icon name="shoppingBag" />}
			{link.iconType === 'arrow' && <Icon name="externalLinkArrow" />}
		</div>
	)
}

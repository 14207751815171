import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import clsx from 'clsx'
import { Fragment, useEffect, useState } from 'react'
import Modal from 'react-modal'
import type { ImageResult } from '../data/content/ImageFragment'
import type { PrimaryLinkItemResult } from '../data/content/PrimaryLinkItemFragment'
import type { SecondaryLinksItemResult } from '../data/content/SecondaryLinksItemFragment'
import type { SocialLinkListResult } from '../data/content/SocialLinkListFragment'
import { ContemberImage } from './ContemberImage'
import modalStyles from './Modal.module.sass'
import styles from './Navigation.module.sass'
import { NavigationSocialLinks } from './NavigationSocialLinks'
import { PrimaryLinksItem } from './PrimaryLinksItem'
import { Sandwich } from './Sandwich'
import { SecondaryLinksItem } from './SecondaryLinksItem'

type NavigationProps = {
	isOpen: boolean
	onClose: () => void
	primaryLinks?: PrimaryLinkItemResult[]
	secondaryLinks?: SecondaryLinksItemResult[]
	socialLinks?: SocialLinkListResult
	logo?: ImageResult
}

Modal.setAppElement('#__next')

export const Navigation = (props: NavigationProps) => {
	const { isOpen, onClose, primaryLinks, secondaryLinks, socialLinks, logo } = props

	const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null)

	useEffect(() => {
		if (targetRef) {
			if (isOpen) {
				disableBodyScroll(targetRef)
			} else {
				enableBodyScroll(targetRef)
			}
		}
	}, [isOpen, targetRef])

	return (
		<Modal
			closeTimeoutMS={500}
			isOpen={isOpen}
			onRequestClose={onClose}
			overlayClassName={clsx(modalStyles.overlay, isOpen && modalStyles.isOpen)}
			className={modalStyles.content}
			overlayRef={setTargetRef}>
			<div className={modalStyles.contentIn}>
				<div className={styles.sandwichButton}>
					<Sandwich modalIsOpen={isOpen} onClick={onClose} />
				</div>
				<div>
					<div className={styles.primaryLinks}>
						{primaryLinks?.map((item) => {
							return (
								<Fragment key={item.id}>
									<PrimaryLinksItem item={item} />
								</Fragment>
							)
						})}
					</div>
					<div className={styles.secondaryLinks}>
						{secondaryLinks?.map((item) => {
							return (
								<Fragment key={item.id}>{item.link && <SecondaryLinksItem link={item} />}</Fragment>
							)
						})}
					</div>
				</div>
				<div>
					<NavigationSocialLinks links={socialLinks} />
				</div>
			</div>
			{logo && (
				<div className={modalStyles.logoWrapper}>
					<div className={modalStyles.logo}>
						<ContemberImage image={logo} layout="fill" objectFit="contain" />
					</div>
				</div>
			)}
		</Modal>
	)
}

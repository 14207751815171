import { AmbiRichTextRenderer } from './AmbiRichTextRenderer'
import styles from './PageLead.module.sass'

type PageLeadProps = {
	title?: string
}

export const PageLead = (props: PageLeadProps) => {
	const { title } = props
	return <div className={styles.title}>{title && <AmbiRichTextRenderer source={title} />}</div>
}

import React from 'react'
import type { CommonPageProps } from '../../pages/[[...page]]'

interface Values {
	[key: string]: string
}

interface AppTranslationsStateProps {
	primaryValues: Values
	fallbackValues: Values
}

const defaultValue: AppTranslationsStateProps = {
	primaryValues: {},
	fallbackValues: {},
}

export const TranslationsContext = React.createContext(defaultValue)
export const TranslationsContextProvider: React.FunctionComponent<{
	data: CommonPageProps['dataFromContember']['getTranslationDomain']
}> = ({ children, data }) => {
	const toKeyValue = (
		values: Array<{
			key?:
				| {
						identifier: string
				  }
				| undefined
			value: string
		}>
	) => Object.fromEntries(values.map((value) => [value.key?.identifier || '', value.value]))
	const primaryValues = toKeyValue(data?.cataloguesByIdentifier?.values || [])
	const fallbackValues = toKeyValue(data?.cataloguesByIdentifier?.fallback?.values || [])
	return (
		<TranslationsContext.Provider value={{ primaryValues, fallbackValues }}>
			{children}
		</TranslationsContext.Provider>
	)
}

import { HidingHeader } from 'hiding-header-react'
import type React from 'react'
import type { SiteResult } from '../data/content/SiteFragment'
import { Footer } from './Footer'
import { Header } from './Header'
import styles from './Layout.module.sass'
import type { LocaleSwitcherProps } from './LocaleSwitcher'

type LayoutProps = {
	children: React.ReactNode
	localeSwitcher: LocaleSwitcherProps
	header: SiteResult['getHeader']
	footer: SiteResult['getFooter']
}

export const Layout = (props: LayoutProps) => {
	const { children, header, footer, localeSwitcher } = props

	return (
		<div className={styles.wrapper}>
			<HidingHeader>
				<header className={styles.header}>
					<Header
						header={header}
						pageLocales={localeSwitcher.pageLocales}
						activeLocale={localeSwitcher.activeLocale}
					/>
				</header>
			</HidingHeader>
			<main className={styles.main}>{children}</main>
			<footer>
				<Footer footer={footer} />
			</footer>
		</div>
	)
}

import { Fragment } from 'react'
import type { RestaurantResult } from '../data/content/RestaurantFragment'
import styles from './RestaurantsSection.module.sass'
import { RestaurantTile } from './RestaurantTile'

const tileWeights = [
	3, 3, 2, 4, 2, 2, 2, 6, 4, 4, 8, 4, 2, 2, 8, 2, 4, 2, 8, 4, 2, 2, 8, 2, 4, 2,
] as const

type RestaurantsSectionProps = {
	restaurants?: RestaurantResult[]
}

export const RestaurantsSection = (props: RestaurantsSectionProps) => {
	const { restaurants } = props

	restaurants?.sort((restaurantA, restaurantB) => {
		const isAActive = restaurantA.localesByLocale?.isEshopActive
		const isBActive = restaurantB.localesByLocale?.isEshopActive
		return isAActive === isBActive ? 0 : isAActive ? -1 : 1
	})

	return (
		<div className={styles.items}>
			{restaurants?.map((item, index) => {
				const tileWeight = tileWeights[index % tileWeights.length]

				return (
					<Fragment key={item.id}>
						<RestaurantTile tileWeight={tileWeight} tile={item} />
					</Fragment>
				)
			})}
		</div>
	)
}

import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useRef } from 'react'
import { useTranslate } from '../hooks/useTranslate'
import { useUrlQueryParam } from '../utils/useUrlQueryParam'
import styles from './RestaurantCarouselItem.module.sass'

type RestaurantCarouselItemProps = {
	index: number
	title: string
	activeFilter?: string | string[]
	slug: string
	scrollTo: (index: number, offsetLeft: number) => void
}

export const RestaurantCarouselItem = (props: RestaurantCarouselItemProps) => {
	const { title, activeFilter, slug, scrollTo, index } = props

	const translations = useTranslate()
	const { query, pathname } = useRouter()
	const newQuery = useUrlQueryParam(query, translations('restaurants.filter.key'), slug)

	const linkRef = useRef<HTMLButtonElement | null>(null)

	return (
		<Link href={{ pathname, query: newQuery }}>
			<button
				ref={linkRef}
				onClick={() => scrollTo(index, linkRef.current?.offsetLeft || 0)}
				className={clsx(styles.wrapper, activeFilter === slug && styles.activeFilter)}>
				{title}
			</button>
		</Link>
	)
}

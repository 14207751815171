import clsx from 'clsx'
import type { SiteResult } from '../data/content/SiteFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import styles from './Footer.module.sass'
import { FooterContact } from './FooterContact'
import { FooterLinks } from './FooterLinks'

type FooterProps = {
	footer: SiteResult['getFooter']
}

export const Footer = (props: FooterProps) => {
	const { footer } = props
	return (
		<div className={styles.wrapper}>
			<Container padding="narrow">
				<div className={styles.group}>
					<FooterLinks footerLinks={footer?.localesByLocale?.linksSection} />
				</div>
				<div className={clsx(styles.group, styles.contact)}>
					<span className={styles.separator} />
					<FooterContact footerContact={footer?.localesByLocale?.contactSection} />
				</div>
				<div className={clsx(styles.group, styles.logo)}>
					<span className={styles.separator} />
					{footer?.logo && (
						<div className={styles.logoWrapper}>
							<div className={styles.logo}>
								<ContemberImage image={footer?.logo} layout="fill" objectFit="contain" />
							</div>
						</div>
					)}
				</div>
			</Container>
		</div>
	)
}

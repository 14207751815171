import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import type { CommonPageProps } from '../../pages/[[...page]]'
import { Banner } from '../components/Banner'
import { Container } from '../components/Container'
import { PageLead } from '../components/PageLead'
import { RestaurantCarouselFilter } from '../components/RestaurantCarouselFilter'
import { RestaurantsSection } from '../components/RestaurantsSection'
import { SocialLinks } from '../components/SocialLinks'
import type { CategoryResult } from '../data/content/CategoryFrgament'
import type { RestaurantResult } from '../data/content/RestaurantFragment'
import { useTranslate } from '../hooks/useTranslate'

type FrontPageProps = PickRequiredValues<CommonPageProps, 'frontPage'> & {
	restaurants?: RestaurantResult[]
	categoryList?: CategoryResult[]
}

export function FrontPage(props: FrontPageProps) {
	const { title, socialLinks, root, seo } = props.frontPage
	const { restaurants, categoryList } = props

	const { query } = useRouter()
	const [activeFilter, setActiveFilter] = useState<string | string[]>()

	const translations = useTranslate()

	useEffect(() => {
		if (
			query[translations('restaurants.filter.key')] &&
			typeof query[translations('restaurants.filter.key')] === 'string'
		) {
			setActiveFilter(query[translations('restaurants.filter.key')])
		} else {
			setActiveFilter(undefined)
		}
	}, [query, translations])

	const filteredRestaurants =
		activeFilter !== undefined
			? restaurants?.filter((restaurant) => {
					let categoryMatches = false
					restaurant.categories.map((category) => {
						if (activeFilter === category.localesByLocale?.slug) {
							categoryMatches = true
						}
					})

					return categoryMatches
			  })
			: restaurants

	return (
		<>
			<Head>
				<title>{seo?.title}</title>
			</Head>
			<Container>
				<PageLead title={title} />
				<RestaurantCarouselFilter categoryList={categoryList} activeFilter={activeFilter} />
				{restaurants && <RestaurantsSection restaurants={filteredRestaurants} />}
				<SocialLinks text={socialLinks} />
				{root?.banner && <Banner banner={root?.banner} />}
			</Container>
		</>
	)
}

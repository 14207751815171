import useIntersectionObserver from '@react-hook/intersection-observer'
import clsx from 'clsx'
import { Fragment, useCallback, useRef, useState } from 'react'
import { scrollBy } from 'simple-smooth-scroll-by'
import type { CategoryResult } from '../data/content/CategoryFrgament'
import { CarouselArrow } from './CarouselArrow'
import styles from './RestaurantCarouselFilter.module.sass'
import { RestaurantCarouselItem } from './RestaurantCarouselItem'

type RestaurantCarouselFilterProps = {
	categoryList?: CategoryResult[]
	activeFilter?: string | string[]
}

const CAROUSEL_SCROLL_BY_DISTANCE = 100

export const RestaurantCarouselFilter = (props: RestaurantCarouselFilterProps) => {
	const { categoryList, activeFilter } = props
	const carouselRef = useRef<HTMLDivElement>(null)

	const [startCatchRef, setStartCatchRef] = useState<null | HTMLElement>(null)
	const startObserver = useIntersectionObserver(startCatchRef)

	const [endCatchRef, setEndCatchRef] = useState<null | HTMLElement>(null)
	const endObserver = useIntersectionObserver(endCatchRef)

	const scrollToPrevious = useCallback(() => {
		if (carouselRef.current) {
			scrollBy(carouselRef.current, 0, -CAROUSEL_SCROLL_BY_DISTANCE)
		}
	}, [])

	const scrollToNext = useCallback(() => {
		if (carouselRef.current) {
			scrollBy(carouselRef.current, 0, CAROUSEL_SCROLL_BY_DISTANCE)
		}
	}, [])

	const scrollTo = useCallback((index: number, offsetLeft: number) => {
		if (carouselRef.current) {
			const offset = index === 0 ? 18 : 28
			carouselRef.current.scrollLeft = offsetLeft - offset
		}
	}, [])

	return (
		<div className={styles.wrapper}>
			<div className={styles.content} ref={carouselRef}>
				<div className={styles.contentIn}>
					<div className={styles.items}>
						<div ref={setStartCatchRef} />
						{categoryList?.map((item, i) => {
							return (
								<Fragment key={item.id}>
									{item.localesByLocale?.title && (
										<RestaurantCarouselItem
											index={i}
											title={item.localesByLocale.title}
											activeFilter={activeFilter}
											slug={item.localesByLocale.slug}
											scrollTo={scrollTo}
										/>
									)}
								</Fragment>
							)
						})}
						<div ref={setEndCatchRef} className={styles.endAnchor} />
					</div>
				</div>
				<div
					className={clsx(
						styles.controlsPrevious,
						startObserver.isIntersecting && styles.isHidden
					)}>
					<CarouselArrow direction="previous" onClick={scrollToPrevious} />
				</div>
				<div className={clsx(styles.controlsNext, endObserver.isIntersecting && styles.isHidden)}>
					<CarouselArrow direction="next" onClick={scrollToNext} />
				</div>
			</div>
		</div>
	)
}

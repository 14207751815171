import clsx from 'clsx'
import React from 'react'
import type { FooterContactResult } from '../data/content/FooterContactFragment'
import { AmbiRichTextRenderer } from './AmbiRichTextRenderer'
import { ContemberImage } from './ContemberImage'
import styles from './FooterContact.module.sass'

type FooterContactProps = {
	footerContact?: FooterContactResult
}
export const FooterContact = (props: FooterContactProps) => {
	const { footerContact } = props
	return (
		<div className={styles.wrapper}>
			<div className={styles.column}>
				<a href={`mailto:${footerContact?.email}`} className={styles.email}>
					{footerContact?.email}
				</a>
				<a href={`tel:${footerContact?.phone}`} className={styles.phone}>
					{footerContact?.phoneImage && (
						<div className={styles.phoneIconWrapper}>
							<div className={styles.phoneIcon}>
								<ContemberImage
									image={footerContact?.phoneImage}
									layout="fill"
									objectFit="contain"
								/>
							</div>
						</div>
					)}
					{footerContact?.phone}
				</a>
			</div>
			<div className={clsx(styles.column, styles.address)}>
				{footerContact?.address && <AmbiRichTextRenderer source={footerContact?.address} />}
			</div>
			<div className={styles.column}>
				{footerContact?.note && <AmbiRichTextRenderer source={footerContact?.note} />}
				{footerContact?.openingHours && (
					<div className={styles.openingHours}>
						<AmbiRichTextRenderer source={footerContact?.openingHours} />
					</div>
				)}
			</div>
			<div className={styles.column}>
				<div>
					<div>{footerContact?.identificationNumber}</div>
					<div>{footerContact?.valueAddedTaxNumber}</div>
				</div>
				<div className={styles.bussinessRegister}>{footerContact?.bussinessRegister}</div>
			</div>
		</div>
	)
}

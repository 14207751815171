export function detectExternalUrl(url: string) {
	const firstChar = url.slice(0, 1)
	if (firstChar === '/' || firstChar === '#') {
		return false
	}
	if (url.includes(String(process.env.NEXT_PUBLIC_BASE_URL))) {
		return false
	}
	return true
}

import clsx from 'clsx'
import type { FooterLinksResult } from '../data/content/FooterLinksFragment'
import { AmbiRichTextRenderer } from './AmbiRichTextRenderer'
import { Button } from './Button'
import { FooterLinkList } from './FooterLinkList'
import styles from './FooterLinks.module.sass'
import { FooterTitleList } from './FooterTitleList'

type FooterLinksProps = {
	footerLinks?: FooterLinksResult
}
export const FooterLinks = (props: FooterLinksProps) => {
	const { footerLinks } = props

	const footerLinksArr = footerLinks?.titleList?.items ?? []
	const firstHalf = footerLinksArr.slice(0, Math.ceil(footerLinksArr.length / 2))
	const secondHalf = footerLinksArr.slice(Math.ceil(footerLinksArr.length / 2))

	return (
		<div className={styles.wrapper}>
			<div className={clsx(styles.column, styles.linkList)}>
				<FooterLinkList linkList={footerLinks?.linkList} />
			</div>
			<div className={styles.column}>
				<FooterTitleList footerTitleList={firstHalf} />
			</div>
			<div className={styles.column}>
				<FooterTitleList footerTitleList={secondHalf} />
			</div>
			<div className={styles.column}>
				{footerLinks?.buttonsTitle && (
					<div>
						<AmbiRichTextRenderer source={footerLinks?.buttonsTitle} />
					</div>
				)}
				<div className={styles.buttons}>
					{footerLinks?.buttons?.items.map((item) => {
						return (
							<div key={item.id}>
								{item.link && <Button link={item.link} variant="primary" type="link" />}
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

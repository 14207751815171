import { AmbiRichTextRenderer } from './AmbiRichTextRenderer'
import styles from './SocialLinks.module.sass'

type SocialLinksProps = {
	text?: string
}

export const SocialLinks = (props: SocialLinksProps) => {
	const { text } = props
	return (
		<div className={styles.wrapper}>
			{text && (
				<div className={styles.text}>
					<AmbiRichTextRenderer source={text} />
				</div>
			)}
		</div>
	)
}

import clsx from 'clsx'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import type { LinkResult } from '../data/content/LinkFragment'
import styles from './Button.module.sass'
import { ContemberLink } from './ContemberLink'
import { Icon } from './Icon'

type SharedButtonProps = {
	variant?: 'primary' | 'secondary'
	inverted?: boolean
}

type LinkButtonProps = {
	type: 'link'
	link: LinkResult | string
}

type ButtonButtonProps = {
	type: 'button'
	onClick: () => void
	showSubItems?: boolean
}

export type ButtonProps = SharedButtonProps & (LinkButtonProps | ButtonButtonProps)

export const Button: FunctionComponent<ButtonProps> = (props) => {
	const { variant = 'primary', inverted, children, ...otherProps } = props

	return (
		<>
			{otherProps.type === 'link' && (
				<>
					{typeof otherProps.link === 'string' ? (
						<>
							<Link href={otherProps.link}>
								<a>
									<div
										className={clsx(
											styles.wrapper,
											styles[`variant_${variant}`],
											inverted && styles.is_inverted
										)}>
										<span className={clsx(styles.title)}>{children}</span>
									</div>
								</a>
							</Link>
						</>
					) : (
						<ContemberLink link={otherProps.link}>
							<div className={clsx(styles.wrapper, styles[`variant_${variant}`])}>
								<span className={clsx(styles.title)}>{children ?? otherProps.link?.title}</span>
							</div>
						</ContemberLink>
					)}
				</>
			)}
			{otherProps.type === 'button' && (
				<button
					className={clsx(
						styles.wrapper,
						styles[`variant_${variant}`],
						otherProps.type === 'button' && styles.buttonTextAlign
					)}
					onClick={otherProps.onClick}>
					<span className={clsx(styles.title)}>{children}</span>
					<div className={clsx(styles.arrow, otherProps.showSubItems && styles.arrowRotate)}>
						<Icon name="arrowDown" />
					</div>
				</button>
			)}
		</>
	)
}

import clsx from 'clsx'
import Link from 'next/link'
import { CSSProperties, useState } from 'react'
import type { BannerResult } from '../data/content/BannerFragment'
import styles from './Banner.module.sass'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'

type BannerProps = {
	banner: BannerResult
}

export const Banner = (props: BannerProps) => {
	const { banner } = props
	const [showBanner, setShowBanner] = useState(true)

	return (
		<>
			<div className={clsx(styles.wrapper, showBanner && styles.isVisible)}>
				<Container>
					<div className={styles.content}>
						<button
							onClick={() => setShowBanner(false)}
							className={styles.button}
							style={
								banner.textColor
									? ({
											'--Banner-buttonBackground': `${banner.textColor}`,
									  } as CSSProperties)
									: ({
											'--Banner-buttonBackground': '#000',
									  } as CSSProperties)
							}></button>
						<Link href={banner.localesByLocale?.url || '/'}>
							<a className={styles.contentIn} target="_blank">
								{banner?.image && (
									<ContemberImage image={banner?.image} layout="fill" objectFit="cover" />
								)}
								<div
									className={styles.textWrapper}
									style={
										banner.textColor
											? ({
													'--Banner-textColor': `${banner.textColor}`,
											  } as CSSProperties)
											: ({
													'--Banner-textColor': '#000',
											  } as CSSProperties)
									}>
									<div className={styles.title}>{banner?.localesByLocale?.title}</div>
									{banner?.localesByLocale?.text && (
										<div className={styles.text}>{banner?.localesByLocale?.text}</div>
									)}
								</div>
							</a>
						</Link>
					</div>
				</Container>
			</div>
		</>
	)
}

import clsx from 'clsx'
import styles from './Sandwich.module.sass'

type SandwichProps = {
	title?: string
	onClick?: () => void
	modalIsOpen?: boolean
}

export const Sandwich = (props: SandwichProps) => {
	const { title, onClick, modalIsOpen } = props
	return (
		<button className={clsx(styles.wrapper, modalIsOpen && styles.modalIsOpen)} onClick={onClick}>
			<div className={styles.button}>
				<div className={clsx(styles.sandwich, modalIsOpen && styles.modalIsOpen)}>
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
			<div className={styles.title}>{title}</div>
		</button>
	)
}

import clsx from 'clsx'
import type { LinkResult } from '../data/content/LinkFragment'
import { ContemberLink } from './ContemberLink'
import { Icon } from './Icon'
import styles from './PrimaryLink.module.sass'

type PrimaryLinkProps = {
	link: LinkResult
	subLinksLength?: number
	subLink?: boolean
	showSubItems?: boolean
}

export const PrimaryLink = (props: PrimaryLinkProps) => {
	const { link, subLinksLength, subLink, showSubItems } = props
	return (
		<div className={clsx(styles.wrapper, subLink && styles.subLinkWrapper)}>
			<div className={styles.link}>
				<ContemberLink link={link} />
			</div>
			{subLinksLength && subLinksLength > 0 ? (
				<div className={clsx(styles.arrow, showSubItems && styles.rotateArrow)}>
					<Icon name="navigationArrow" />
				</div>
			) : null}
		</div>
	)
}

import type { InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import Script from 'next/script'
import React from 'react'
import { Layout } from '../app/components/Layout'
import type { LocaleSwitcherProps } from '../app/components/LocaleSwitcher'
import { TranslationsContextProvider } from '../app/contexts/TranslationsContext'
import { combineUrl } from '../app/data/combineUrl'
import { CategoryFragment } from '../app/data/content/CategoryFrgament'
import { FrontPageLocaleFragment } from '../app/data/content/FrontPageLocaleFragment'
import { RestaurantFragment } from '../app/data/content/RestaurantFragment'
import { SiteFragment } from '../app/data/content/SiteFragment'
import { SubRestaurantsPageLocaleFragment } from '../app/data/content/SubRestaurantsPage'
import { FrontPage } from '../app/pages/FrontPage'
import { SubRestaurantsPage } from '../app/pages/SubRestaurantsPage'
import { filterNonEmpty } from '../app/utils/filterNonEmpty'
import { OrderDirection, Thunder } from '../generated/content'
import { RespondWithNotFound, serverPropsHandler } from '../libs/next/api/handlers'
import { getZeusConnection } from '../libs/next/loaders/graphql'

export type CommonPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export default function CommonPage(props: CommonPageProps) {
	const page = props.frontPage || props.subRestaurantsPage
	const localeSwitecher: LocaleSwitcherProps = {
		activeLocale: props.urlInfo.locale,
		pageLocales: page?.root,
	}

	return (
		<TranslationsContextProvider data={props.dataFromContember.getTranslationDomain}>
			<Head>
				<title>Ambiente – home</title>
				<link rel="icon" href="/favicon.ico" />
			</Head>

			<Script
				id="hotjar"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
						(function(h,o,t,j,a,r){
							h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
							h._hjSettings={hjid:3002161,hjsv:6};
							a=o.getElementsByTagName('head')[0];
							r=o.createElement('script');r.async=1;
							r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
							a.appendChild(r);
						})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
						`,
				}}
			/>

			<Layout
				header={props.dataFromContember.getHeader}
				localeSwitcher={localeSwitecher}
				footer={props.dataFromContember.getFooter}>
				{props.dataFromContember.getLinkable?.frontPage && (
					<FrontPage
						frontPage={props.dataFromContember.getLinkable?.frontPage}
						restaurants={props.restaurants}
						categoryList={props.categoryList?.listCategory}
					/>
				)}
				{props.dataFromContember.getLinkable?.subRestaurantsPage && (
					<SubRestaurantsPage
						restaurants={props.restaurants}
						subRestaurantsPage={props.dataFromContember.getLinkable?.subRestaurantsPage}
					/>
				)}
			</Layout>
		</TranslationsContextProvider>
	)
}

export const getServerSideProps = serverPropsHandler(async (context) => {
	const content = getZeusConnection(Thunder, 'content')

	const urlInfo = combineUrl(context)

	const url = `${urlInfo.path}`

	const dataFromContember = await content('query')({
		...SiteFragment(urlInfo.locale),
		getLinkable: [
			{ by: { url } },
			{
				id: true,
				url: true,
				frontPage: [{}, FrontPageLocaleFragment(urlInfo.locale)],
				subRestaurantsPage: [{}, SubRestaurantsPageLocaleFragment(urlInfo.locale)],
			},
		],
		getTranslationDomain: [
			{ by: { identifier: 'app' } },
			{
				cataloguesByIdentifier: [
					{ by: { identifier: { code: urlInfo.locale } } },
					{
						values: [{}, { key: [{}, { identifier: true }], value: true }],
						fallback: [{}, { values: [{}, { key: [{}, { identifier: true }], value: true }] }],
					},
				],
			},
		],
	})

	if (
		!dataFromContember.getLinkable?.frontPage &&
		!dataFromContember.getLinkable?.subRestaurantsPage
	) {
		throw new RespondWithNotFound('Page not found')
	}

	const { frontPage, subRestaurantsPage } = dataFromContember.getLinkable ?? {}

	const restaurantsList =
		frontPage &&
		(await content('query')({
			listRestaurant: [
				{ orderBy: [{ order: OrderDirection.asc }], filter: { item: { id: { isNull: true } } } },
				RestaurantFragment(urlInfo.locale),
			],
		}))

	let restaurants = restaurantsList?.listRestaurant
	if (subRestaurantsPage) {
		restaurants = dataFromContember.getLinkable.subRestaurantsPage?.root?.parentRestaurant?.subItems
			.map((item) => item.restaurant)
			.filter(filterNonEmpty)
		console.log(restaurants)
	}

	const restaurantsIDList = restaurants?.map((restaurant) => restaurant.id) ?? []

	const categoryList =
		frontPage &&
		(await content('query')({
			listCategory: [
				{
					orderBy: [{ order: OrderDirection.asc }],
					filter: {
						restaurants: {
							id: {
								in: restaurantsIDList,
							},
						},
					},
				},
				CategoryFragment(urlInfo.locale),
			],
		}))

	return {
		props: {
			url,
			urlInfo,
			dataFromContember,
			serverTime: new Date().toString(),
			frontPage: frontPage,
			subRestaurantsPage: subRestaurantsPage,
			restaurants,
			categoryList: categoryList,
		},
	}
})

import Head from 'next/head'
import type { CommonPageProps } from '../../pages/[[...page]]'
import { Container } from '../components/Container'
import { PageLead } from '../components/PageLead'
import { RestaurantsSection } from '../components/RestaurantsSection'
import type { RestaurantResult } from '../data/content/RestaurantFragment'

type SubRestaurantsPageProps = PickRequiredValues<CommonPageProps, 'subRestaurantsPage'> & {
	restaurants?: RestaurantResult[]
}

export function SubRestaurantsPage(props: SubRestaurantsPageProps) {
	const { title, seo } = props.subRestaurantsPage

	return (
		<>
			<Head>
				<title>{seo?.title}</title>
			</Head>
			<Container>
				{title && <PageLead title={title} />}

				<RestaurantsSection restaurants={props.restaurants} />
			</Container>
		</>
	)
}

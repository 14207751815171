import { RichTextRenderer, RichTextRendererProps } from '@contember/react-client'
import { detectExternalUrl } from '../utils/detectExternalUrl'

const renderElement: RichTextRendererProps['renderElement'] = (ref) => {
	if (ref.element.type === 'anchor') {
		if (detectExternalUrl(ref.element.href)) {
			return (
				<a href={ref.element.href} target="_blank" rel="noreferrer">
					{ref.children}
				</a>
			)
		}
	}
	return ref.fallback
}

export const AmbiRichTextRenderer: React.FunctionComponent<RichTextRendererProps> = (props) => {
	return <RichTextRenderer {...props} renderElement={renderElement} />
}

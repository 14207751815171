import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import { Collapsible } from 'collapsible-react-component'
import Link from 'next/link'
import { useMemo, useState, type CSSProperties, type FunctionComponent } from 'react'
import type { ImageResult } from '../data/content/ImageFragment'
import type { RestaurantResult } from '../data/content/RestaurantFragment'
import { useTranslate } from '../hooks/useTranslate'
import { Button } from './Button'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import { RestaurantTag } from './RestaurantTag'
import styles from './RestaurantTile.module.sass'

export type RestaurantTileProps = {
	tileWeight: number
	tile: RestaurantResult
}

export const RestaurantTile: FunctionComponent<RestaurantTileProps> = ({ tile, tileWeight }) => {
	const mainUrl = tile.localesByLocale?.url

	const [open, setOpen] = useState(false)

	const translate = useTranslate()

	const isOnTopOfPicture = useMemo(
		() => tileWeight === 8 || tileWeight === 4 || tileWeight === 2,
		[tileWeight]
	)

	const tagItems = useMemo(() => {
		const result = tile.tags.map((tag) => ({
			text: tag.localesByLocale?.title,
			textColor: tag.titleColor,
			backgroundColor: tag.backgroundColor,
			id: tag.id,
		}))

		if (!tile.localesByLocale?.isDeliveryActive) {
			result.push({
				text: translate('RestaurantTag.delivery.notActive'),
				textColor: '#000000',
				backgroundColor: '#ffffff',
				id: 'delivery-not-active',
			})
		}

		if (!tile.localesByLocale?.isEshopActive) {
			result.push({
				text: translate('RestaurantTag.eshop.notActive'),
				textColor: '#000000',
				backgroundColor: '#ffffff',
				id: 'eshop-not-active',
			})
		}

		return result
	}, [
		tile.localesByLocale?.isDeliveryActive,
		tile.localesByLocale?.isEshopActive,
		tile.tags,
		translate,
	])

	return (
		<div
			className={clsx(
				styles.wrapper,
				styles[`is_tileWeight_${tileWeight}`],
				!tile.localesByLocale?.isEshopActive && styles.eshopNotActive
			)}
			style={{ '--RestaurantPageTileList-item-gridArea': tileWeight } as CSSProperties}>
			<div className={styles.imageWrapper}>
				{tagItems.length > 0 && (
					<div className={styles.tags}>
						{tagItems.map((tag) => (
							<RestaurantTag
								key={tag.id}
								text={tag.text || ''}
								textColor={tag.textColor}
								backgroundColor={tag.backgroundColor}
							/>
						))}
					</div>
				)}
				{tile.image &&
					(mainUrl ? (
						<Link href={mainUrl}>
							<TileImage image={tile.image} tileWeight={tileWeight} />
						</Link>
					) : (
						<TileImage image={tile.image} tileWeight={tileWeight} />
					))}
			</div>
			<div className={styles.content}>
				<div>
					<div className={styles.title}>
						{mainUrl ? (
							<Link href={mainUrl}>{tile.localesByLocale?.title}</Link>
						) : (
							tile.localesByLocale?.title
						)}
					</div>
					<div className={styles.description}>{tile.localesByLocale?.description}</div>
					<div
						className={clsx(
							styles.openingHours,
							tile.subItems.length > 0 && styles.subRestaurantOpeningHours
						)}>
						{tile.openingHours && <RichTextRenderer source={tile.openingHours} />}
					</div>
					{(tile.subItems.length ?? 0) > 0 && (
						<div className={styles.subItemsWrapper}>
							<button
								className={styles.subItemsButton}
								onClick={() => setOpen(!open)}
								type="button">
								<div>{translate('RestaurantSubitems.button')}</div>
								<div className={clsx(styles.chevron, open && styles.isOpen)}>
									<Icon name="chevron" />
								</div>
							</button>
							<Collapsible open={open}>
								<div className={styles.subItems}>
									{tile.subItems.map((item) => {
										return (
											<div
												key={item.id}
												className={clsx(styles.subItemIn, !item.restaurant && styles.isEmpty)}>
												{item.restaurant?.localesByLocale?.url ? (
													<Link href={item.restaurant?.localesByLocale?.url}>
														{item.restaurant?.localesByLocale?.title}
													</Link>
												) : (
													item.restaurant?.localesByLocale?.title
												)}
											</div>
										)
									})}
								</div>
							</Collapsible>
						</div>
					)}
				</div>
				<div
					className={styles.buttons}
					style={{ '--Button-height-primary': 'calc(40rem/16)' } as CSSProperties}>
					{tile.localesByLocale?.secondaryUrl && (
						<>
							<div className={styles.mobileSecondaryButton}>
								<Button link={tile.localesByLocale.secondaryUrl} variant="primary" type="link">
									{translate('RestaurntTile.secondaryButton')}
								</Button>
							</div>
							<div className={styles.desktopSecondaryButton}>
								<Button
									link={tile.localesByLocale.secondaryUrl}
									variant="primary"
									type="link"
									inverted={isOnTopOfPicture}>
									{translate('RestaurntTile.secondaryButton')}
								</Button>
							</div>
						</>
					)}
					{tile.localesByLocale?.url && tile.localesByLocale?.isEshopActive && (
						<Button link={tile.localesByLocale?.url} variant="secondary" type="link">
							{translate('Order.button')}
						</Button>
					)}
				</div>
			</div>
		</div>
	)
}

const TileImage: FunctionComponent<{ image: ImageResult; tileWeight: number }> = ({
	image,
	tileWeight,
}) => {
	const contentMaxWidth = 1230 as const
	const tableSize = useMemo(() => (100 / 8) * tileWeight, [tileWeight])
	const desktopSize = useMemo(
		() => (contentMaxWidth / 8) * tileWeight,
		[tileWeight, contentMaxWidth]
	)

	return (
		<div className={styles.image}>
			<ContemberImage
				image={image}
				layout="fill"
				objectFit="cover"
				sizes={`(min-width: ${contentMaxWidth}px) ${desktopSize}px, (min-width: 800px) ${tableSize}vw, 100vw`}
			/>
		</div>
	)
}

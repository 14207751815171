import clsx from 'clsx'
import styles from './CarouselArrow.module.sass'

type ArrowProps = {
	direction?: 'previous' | 'next'
	onClick: () => void
}

export const CarouselArrow = (props: ArrowProps) => {
	const { direction = 'next', onClick } = props
	return (
		<button className={styles.wrapper} onClick={onClick}>
			<div
				className={clsx(
					styles.arrow,
					direction === 'next' && styles.next,
					direction === 'previous' && styles.previous
				)}
			/>
		</button>
	)
}

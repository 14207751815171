import type React from 'react'
import styles from './RestaurantTag.module.sass'

type RestaurantTagProps = {
	text: string
	textColor?: string
	backgroundColor?: string
}

export const RestaurantTag = (props: RestaurantTagProps) => {
	const { text, textColor, backgroundColor } = props
	return (
		<div
			className={styles.wrapper}
			style={
				{
					'--RestaurantTag-background': `${backgroundColor || '#000'}`,
					'--RestaurantTag-fontColor': `${textColor || '#000'}`,
				} as React.CSSProperties
			}>
			{text}
		</div>
	)
}

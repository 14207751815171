import clsx from 'clsx'
import styles from './Container.module.sass'

type ContainerProps = {
	children: React.ReactNode
	contentWidth?: 'wide'
	padding?: 'narrow' | 'normal'
}

export const Container = (props: ContainerProps) => {
	const { children, contentWidth, padding = 'normal' } = props
	return (
		<div
			className={clsx(
				styles.wrapper,
				styles[`view-${contentWidth}`],
				styles[`padding-${padding}`]
			)}>
			{children}
		</div>
	)
}
